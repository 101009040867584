<template>
  <div>
    <div class="location-list-filters" v-if="frontofficesFilter === true">
      <div v-if="frontOffices && frontOffices.length > 0" class="location-list-filters__section">
        <div class="location-list-filters__section__label">
          {{ $t('locations.list.filters.frontOffice') }}
        </div>
        <ui-dropdown
          id="dropdown-frontOffices"
          :value="filters.selectedfrontOffice"
          :options="frontOffices"
          :no-absolute="true"
          :no-radius="true"
          :placeholder="$t('locations.list.filters.selectFrontOffice')"
          label="name"
          track-by="id"
          @input="onSelectedfrontOffice"
        />
      </div>
      <div class="location-list-filters__section__buttons">
        <div class="location-list-filters__section__buttons__reset">
          <ui-button button="secondary" icon="reset" :label="$t('locations.list.filters.reset')" @click="reset" />
        </div>
      </div>
    </div>
    <div class="location-list-filters" v-else>
      <div class="location-list-filters__section">
        <div class="location-list-filters__section__label">
          {{ $t('locations.list.filters.status') }}
        </div>
        <div class="location-list-filters__section__radios">
          <ui-radio
            class="location-list-filters__section__radios__radio"
            id="status-all"
            value="ENABLED|DISABLED"
            name="status"
            :reverse="true"
            v-model="filters.status"
          >
            {{ $t('locations.list.filters.all') }}
          </ui-radio>
          <ui-radio
            class="location-list-filters__section__radios__radio"
            id="status-enabled"
            value="ENABLED"
            name="status"
            :reverse="true"
            v-model="filters.status"
          >
            {{ $t('locations.list.filters.enabled') }}
          </ui-radio>
          <ui-radio
            class="location-list-filters__section__radios__radio"
            id="status-disabled"
            value="DISABLED"
            name="status"
            :reverse="true"
            v-model="filters.status"
          >
            {{ $t('locations.list.filters.disabled') }}
          </ui-radio>
        </div>
      </div>

      <div v-if="productLocatorStatus === '1'" class="location-list-filters__section">
        <div class="location-list-filters__section__label">
          {{ $t('locations.list.filters.locatorStatus') }}
        </div>
        <div class="location-list-filters__section__radios">
          <ui-radio
            class="location-list-filters__section__radios__radio"
            id="locatorStatus-all"
            value="ENABLED|DISABLED"
            name="locatorStatus"
            :reverse="true"
            v-model="filters.productLocatorStatus"
          >
            {{ $t('locations.list.filters.all') }}
          </ui-radio>
          <ui-radio
            class="location-list-filters__section__radios__radio"
            id="locatorStatus-enabled"
            value="ENABLED"
            name="locatorStatus"
            :reverse="true"
            v-model="filters.productLocatorStatus"
          >
            {{ $t('locations.list.filters.enabled') }}
          </ui-radio>
          <!--<ui-radio
            class="location-list-filters__section__radios__radio"
            id="locatorStatus-disabled"
            value="DISABLED"
            name="locatorStatus"
            :reverse="true"
            v-model="filters.productLocatorStatus"
          >
            {{ $t('locations.list.filters.disabled') }}
          </ui-radio>-->
        </div>
      </div>

      <div class="location-list-filters__section">
        <ui-checkbox
          id="without-country"
          class="location-list-filters__section__checkbox"
          :reverse="true"
          align="space-between"
          v-model="filters.withoutCountry"
        >
          {{ $t('locations.list.filters.withoutCountry') }}
        </ui-checkbox>
      </div>
      <div class="location-list-filters__section">
        <ui-checkbox
          id="without-latitude"
          class="location-list-filters__section__checkbox"
          :reverse="true"
          align="space-between"
          v-model="filters.withoutLatitude"
        >
          {{ $t('locations.list.filters.withoutLatitude') }}
        </ui-checkbox>
      </div>
      <div class="location-list-filters__section__buttons">
        <div class="location-list-filters__section__buttons__reset">
          <ui-button button="secondary" icon="reset" :label="$t('locations.list.filters.reset')" @click="reset" />
        </div>
        <div class="location-list-filters__section__buttons__close">
          <ui-button button="primary" v-close-popover icon="close" :label="$t('locations.list.filters.close')" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UiRadio from '@/components/UI/Radio.vue'
import UiCheckbox from '@/components/UI/Checkbox.vue'
import UiDropdown from '@/components/UI/Dropdown.vue'
import UiButton from '@/components/UI/Button.vue'
import { mapState, mapActions } from 'vuex'

const getStringifiedStatus = value => {
  switch (value) {
    case 'ENABLED':
      return 'status:1'
    case 'DISABLED':
      return 'status!1'
    case 'ENABLED|DISABLED':
    default:
      return ''
  }
}
const getStringifiedProductLocatorStatus = value => {
  switch (value) {
    case 'ENABLED':
      return 'productLocatorStatus:1'
    case 'DISABLED':
      return 'productLocatorStatus!1'
    case 'ENABLED|DISABLED':
    default:
      return ''
  }
}
const getStringifiedWithoutCountry = value => (value === true ? 'countryCode:null' : '')
const getStringifiedwithoutLatitude = value => (value === true ? 'latitude:null' : '')
const getStringifiedFrontOffices = value => (value.tag && value.tag.length > 0 ? `tags:${value.tag}` : '')

const defaultFilters = {
  status: 'ENABLED|DISABLED',
  productLocatorStatus: 'ENABLED|DISABLED',
  withoutCountry: false,
  withoutLatitude: false,
  selectedfrontOffice: '',
}

export default {
  name: 'FrontOfficesListFilter',
  components: {
    UiRadio,
    UiCheckbox,
    UiDropdown,
    UiButton,
  },
  props: {
    productLocatorStatus: {
      type: String,
      required: false,
      default: '0',
    },
    frontofficesFilter: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      filters: {
        ...defaultFilters,
      },
    }
  },
  mounted() {
    this.getFrontOffices({})
  },
  computed: {
    ...mapState({
      frontOffices: state => state.frontoffice.frontOffices,
    }),
    stringifiedSearch() {
      return [
        getStringifiedStatus(this.filters.status),
        getStringifiedProductLocatorStatus(this.filters.productLocatorStatus),
        getStringifiedWithoutCountry(this.filters.withoutCountry),
        getStringifiedwithoutLatitude(this.filters.withoutLatitude),
        getStringifiedFrontOffices(this.filters.selectedfrontOffice),
      ]
        .filter(filter => filter && filter.length > 0)
        .join(',')
    },
  },
  watch: {
    filters: {
      deep: true,
      handler: 'onUpdateFilters',
    },
  },
  methods: {
    ...mapActions({
      getFrontOffices: 'frontoffice/getFrontOffices',
    }),
    onUpdateFilters() {
      this.$emit('onUpdateFilters', this.stringifiedSearch)
      this.$emit('frontofficeSelected', this.filters.selectedfrontOffice)
    },
    onSelectedfrontOffice(e) {
      this.filters.selectedfrontOffice = e
    },
    reset() {
      this.filters = { ...defaultFilters }
    },
  },
}
</script>

<style lang="scss" scoped>
.location-list-filters {
  margin: 0 auto;

  &__section {
    display: flex;
    flex-direction: column;
    margin-bottom: 1.5rem;

    @media (min-width: $screen-sm) {
      margin-bottom: $gutter-tablet;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &__label {
      margin-bottom: 0.5rem;
    }

    &__label,
    &__checkbox {
      font-weight: 600;
    }

    &__radios {
      display: flex;
      justify-content: space-between;
      &__radio {
        margin: 0 0.5rem;

        &:first-child {
          margin: 0;
          margin-right: 0.5rem;
        }
        &:last-child {
          margin: 0;
          margin-left: 0.5rem;
        }
      }
    }

    &__buttons {
      display: flex;
      justify-content: space-between;
      margin-bottom: 0;
    }
  }
}
</style>
