<template>
  <div class="locations-list" @scroll.passive="onScroll($event.srcElement, $refs.fixedElement.$el)">
    <screen-header
      class="locations-list__header"
      :title="
        $tc('locations.list.title', locationsPagination.nbResults, {
          brand: currentClient.name,
          count: locationsPagination.nbResults,
        })
      "
      :breadcrumbs="breadcrumbs"
      :is-loading="isLoading && !isScrolling"
      :right-content="true"
    >
      <div class="locations-list__header__publish" v-if="isLoading">
        <skeleton-input margin-left="24px" />
      </div>
      <v-popover
        v-else
        popover-inner-class="locations-list__header__popover--inner"
        popover-arrow-class="tooltip-arrow locations-list__header__popover--arrow"
        placement="bottom"
        :trigger="isMobile ? 'click' : 'hover'"
        :disabled="isPublishing || !currentFrontOfficeIsSet()"
      >
        <ui-button
          class="locations-list__header__popover--inner__publish"
          button="cta"
          icon="publish"
          variant="data"
          :label="$t('frontOffices.id.header.publish.label')"
          :disabled="isPublishing || !currentFrontOfficeIsSet() || frontOfficeRulesDisabledPublish"
          :loader-active="isPublishing"
          @click="!isMobile && publish()"
        />
        <template slot="popover">
          <div class="locations-list__header__popover--inner__sync">
            {{ $t('frontOffices.id.header.tooltip.sync') }}
            <div class="locations-list__header__popover--inner__sync__date">
              {{ dateLastUpdate }}
            </div>
          </div>
          <div class="locations-list__header__popover--inner__time">
            {{ $t('frontOffices.id.header.tooltip.estimatedTime') }}
            {{ estimatedTime + $t('frontOffices.id.header.tooltip.seconds') }}
          </div>
          <div v-if="isMobile" class="locations-list__header__popover--inner__mobileValidation">
            <span
              @click="publish"
              class="locations-list__header__popover--inner__mobileValidation--validate backoffice-icons"
              >success</span
            >
            <span
              v-close-popover
              class="locations-list__header__popover--inner__mobileValidation--close backoffice-icons"
              >error</span
            >
          </div>
        </template>
      </v-popover>
      <skeleton-icon v-if="isLoading" margin-left="24px" width="44px" height="44px" />
      <v-popover
        ref="popoverSelectFrontoffices"
        placement="bottom-end"
        :autoHide="true"
        container=".locations-list__header"
        popoverInnerClass="popover-inner no-overflow"
        v-else-if="!isMobile || !frontofficeIsUnique"
      >
        <ui-button
          class="locations-list__header__cta"
          button="primary"
          variant="data"
          icon="business"
          :label="currentFrontOfficeIsSet() ? currentFrontOffice.name : $t('locations.list.filters.selectFrontOffice')"
          :mobile-toggle="true"
        />
        <template slot="popover" v-if="!frontofficeIsUnique">
          <div class="locations-list__header__popoverFilter">
            <locations-list-filters
              :productLocatorStatus="currentClient.productLocatorStatus"
              :frontofficesFilter="true"
              @onUpdateFilters="onUpdateFilters"
              @frontofficeSelected="frontofficeSelected"
            />
          </div>
        </template>
      </v-popover>
    </screen-header>
    <screen-container :full-height="true">
      <screen-grid :full-height="true">
        <screen-card :full-height="true">
          <template v-slot:body>
            <ui-data-search
              :element-fixed="elementFixed"
              :scroll-direction="scrollDirection"
              :is-mobile="isMobile"
              :is-tablet="isTablet"
              :is-loading="isLoading"
              :is-scrolling="isScrolling"
              :creation-roles="[userRoles.superAdmin, userRoles.brandAdmin, userRoles.agency]"
              view="locations"
              ref="fixedElement"
              :withAddButton="true"
              @add="addLocation"
              @search="onSearch"
              :with-search="true"
              :with-filter="true"
            >
              <template v-slot:filterSlot>
                <locations-list-filters
                  :productLocatorStatus="currentClient.productLocatorStatus"
                  @onUpdateFilters="onUpdateFilters"
                />
              </template>
            </ui-data-search>
            <div class="locations-list__list">
              <ui-data-list
                :items="locationsMobile"
                :is-loading="isLoading"
                :is-scrolling="isScrolling"
                :no-more-data="noMoreData"
                @click="goToLocation({ row: { id: $event.id } })"
              >
                <template v-slot:skeleton>
                  <div class="locations-list__list__content">
                    <skeleton-line height="18px" width="80%" />
                    <skeleton-line height="15px" width="50%" margin-top="3px" />
                  </div>
                  <skeleton-icon margin-left="16px" />
                </template>
                <template v-slot:item="slotProps">
                  <div class="locations-list__list__content">
                    <div class="locations-list__list__content__name">
                      {{ slotProps.item.name }}
                    </div>
                    <address class="locations-list__list__content__address">
                      {{ slotProps.item.address }}, {{ slotProps.item.postal_code }} {{ slotProps.item.city }}
                    </address>
                  </div>
                  <span
                    class="locations-list__list__status backoffice-icons"
                    :class="[`locations-list__list__status--${slotProps.item.status}`]"
                  >
                    <template
                      v-if="
                        slotProps.item.status === '1' ||
                        slotProps.item.status === '10' ||
                        slotProps.item.status === '20'
                      "
                    >
                      success
                    </template>
                    <template v-else>error</template>
                  </span>
                </template>
              </ui-data-list>
            </div>
            <div class="locations-list__table">
              <ui-data-table
                :columns="columns"
                :rows="locations"
                :total-rows="totalRows"
                :is-loading="isLoading || isUpdating"
                :pagination="locationsPagination"
                @loadItems="loadLocations({ filters: stringifiedSearchFilters, ...$event }, true, true)"
                @onCellClick="goToLocation"
              >
                <template slot="data-table-row" slot-scope="props">
                  <router-link
                    class="locations-list__table__link"
                    :to="{ name: 'Location', params: { id: props.row['id'], currentTab: 'general' } }"
                  />
                  <span v-if="props.column.field === 'name'">
                    <div class="locations-list__table__block">
                      <div class="locations-list__table__block__content">
                        <div class="locations-list__table__block__content__name">
                          {{ props.row['name'] }}
                        </div>
                        <address class="locations-list__table__block__content__address">
                          {{ props.row['street1'] }}, {{ props.row['postalCode'] }}
                          {{ props.row['city'] }}
                        </address>
                      </div>
                    </div>
                  </span>
                  <span v-if="props.column.field === 'group'"> {{ props.row['group'] }} </span>
                  <span v-if="props.column.field === 'externalId'"> {{ props.row['externalId'] }} </span>
                  <span v-if="props.column.field === 'id'"> {{ props.row['id'] }} </span>
                  <span v-else-if="props.column.field === 'status'">
                    <span
                      class="locations-list__table__status backoffice-icons"
                      :class="[`locations-list__table__status--${props.row['status']}`]"
                    >
                      <template
                        v-if="
                          props.row['status'] === '1' || props.row['status'] === '10' || props.row['status'] === '20'
                        "
                      >
                        success
                      </template>
                      <template v-else>error</template>
                    </span>
                  </span>
                </template>
              </ui-data-table>
            </div>
          </template>
        </screen-card>
      </screen-grid>
    </screen-container>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { notif } from '@/mixins/notification.mixin'
import UiDataTable from '@/components/UI/DataTable.vue'
import UiDataList from '@/components/UI/DataList.vue'
import UiDataSearch from '@/components/UI/DataSearch.vue'
import UiButton from '@/components/UI/Button.vue'
import ScreenHeader from '@/components/Screen/Header.vue'
import ScreenContainer from '@/components/Screen/Container.vue'
import ScreenGrid from '@/components/Screen/Grid.vue'
import ScreenCard from '@/components/Screen/Card.vue'
import SkeletonLine from '@/components/Skeleton/Line.vue'
import SkeletonIcon from '@/components/Skeleton/Icon.vue'
import SkeletonInput from '@/components/Skeleton/Input.vue'
import { onScroll } from '@/mixins/scroll.mixin'
import { userRoles } from '@/config/permissions.config'
import LocationsListFilters from './ListFilters'
import { formatedDate } from '@/utils/date.util'

export default {
  name: 'LocationsList',
  components: {
    UiDataTable,
    UiDataList,
    UiDataSearch,
    UiButton,
    ScreenHeader,
    ScreenContainer,
    ScreenGrid,
    ScreenCard,
    SkeletonLine,
    SkeletonIcon,
    SkeletonInput,
    LocationsListFilters,
  },
  mixins: [onScroll, notif],
  data() {
    return {
      search: '',
      stringifiedSearchFilters: '',
      isLoading: false,
      isScrolling: false,
      isUpdating: false,
      isPublishing: false,
      noMoreData: false,
      totalRows: 0,
      userRoles,
      currentFrontOffice: () => {},
      frontofficeIsUnique: false,
    }
  },
  async mounted() {
    this.loadLocations(null, true)
    await this.getFrontOffices({})
    if (this.frontOffices.length === 1) {
      this.currentFrontOffice = this.frontOffices[0]
      this.frontofficeIsUnique = true
      this.getFrontOffice({ frontOfficeId: this.frontOffices[0].id })
    }
  },
  watch: {
    canRefreshData: {
      handler() {
        if (this.canRefreshData && !this.isScrolling && !this.noMoreData) {
          this.isScrolling = true
          this.loadLocations({
            page: this.locationsPagination.page + 1,
            filters: this.stringifiedSearchFilters,
          })
        }
      },
    },
    search() {
      this.loadLocations({ filters: this.stringifiedSearchFilters }, true, true)
    },
    stringifiedSearchFilters() {
      this.loadLocations({ filters: this.stringifiedSearchFilters }, true, true)
    },
  },
  computed: {
    ...mapState({
      locations: state => state.location.locations,
      locationsMobile: state => state.location.locationsMobile,
      locationsPagination: state => state.location.locationsPagination,
      currentClient: state => state.user.currentClient,
      frontOffices: state => state.frontoffice.frontOffices,
    }),
    frontOfficeRulesDisabledPublish() {
      return this.currentFrontOffice.algoliaRules?.publish === false
    },
    noData() {
      return this.isLoading || this.noMoreData
    },
    breadcrumbs() {
      return [
        {
          label: this.$t('locations.list.breadcrumb'),
          route: {
            name: 'Locations',
          },
        },
      ]
    },
    columns() {
      return [
        {
          label: this.$t('locations.list.column.location'),
          field: 'name',
        },
        {
          label: this.$t('locations.list.column.group'),
          field: 'group',
        },
        {
          label: this.$t('locations.list.column.externalId'),
          field: 'externalId',
        },
        {
          label: this.$t('locations.list.column.id'),
          field: 'id',
          type: 'number',
        },
        {
          label: this.$t('locations.list.column.status'),
          field: 'status',
        },
      ]
    },
    estimatedTimeInSecond() {
      const locationPerLang = this.publishInfo?.entries * this.locales?.length
      let seconds
      if (!locationPerLang || locationPerLang <= 100) {
        seconds = 5
      } else if (locationPerLang <= 400) {
        seconds = 9
      } else if (locationPerLang <= 1000) {
        seconds = 20
      } else if (locationPerLang <= 4000) {
        seconds = 30
      } else if (locationPerLang <= 16000) {
        seconds = 90
      } else if (locationPerLang <= 32500) {
        seconds = 150
      } else {
        seconds = 180
      }
      return seconds
    },
    dateLastUpdate() {
      return this.currentFrontOffice?.lastAlgoliaImport
        ? formatedDate(this.currentFrontOffice.lastAlgoliaImport, 'LL HH:mm:ss', this.$i18n.locale)
        : this.$t('frontOffices.id.header.tooltip.neverPublish')
    },
    estimatedTime() {
      const minutes = Math.floor(this.estimatedTimeInSecond / 60)
      const seconds = this.estimatedTimeInSecond - minutes * 60

      return `
        ${minutes ? `${minutes} ${this.$t('frontOffices.id.header.tooltip.minutes')}` : ''}
        ${seconds ? Math.round(seconds) : '00'}
      `
    },
  },
  methods: {
    ...mapActions({
      getLocations: 'location/getLocations',
      getFrontOffice: 'frontoffice/getFrontOffice',
      getFrontOffices: 'frontoffice/getFrontOffices',
      publishFrontOffice: 'frontoffice/publishFrontOffice',
    }),
    currentFrontOfficeIsSet() {
      return typeof this.currentFrontOffice === 'object'
    },
    goToLocation(e) {
      this.$router.push({ name: 'Location', params: { id: e.row.id } })
    },
    onUpdateFilters(stringifiedFilters) {
      this.stringifiedSearchFilters = stringifiedFilters
    },
    frontofficeSelected(frontoffice) {
      this.currentFrontOffice = frontoffice
      frontoffice !== '' ? this.getFrontOffice({ frontOfficeId: frontoffice.id }) : null
      this.$refs.popoverSelectFrontoffices.isOpen = false
    },
    onSearch(value) {
      this.search = value
    },
    loadLocations(tableParams = null, resetLocations = false, updating = false) {
      if (updating) {
        this.isUpdating = true
      } else {
        this.isLoading = true
      }
      this.getLocations({ tableParams, resetLocations, search: this.search })
        .then(() => {
          this.totalRows = this.locationsPagination.nbResults
          this.isLoading = false
          this.isScrolling = false
          this.isUpdating = false
        })
        .catch(() => {
          this.noMoreData = true
          this.isLoading = false
          this.isScrolling = false
          this.isUpdating = false
        })
    },
    addLocation() {
      this.$router.push({ name: 'LocationCreate' })
    },
    publish() {
      this.isPublishing = true
      this.publishFrontOffice()
        .then(() => {
          this.isPublishing = false
          this.notificationSuccess('frontOffice', this.currentFrontOffice.name, 'publish')
        })
        .catch(() => {
          this.isPublishing = false
          this.notificationError()
        })
    },
  },
}
</script>

<style lang="scss">
.locations-list {
  padding-bottom: $button-min-height + $gutter-mobile * 2;

  @media (min-width: $screen-sm) {
    padding-bottom: 0;
  }

  &__header {
    &__cta {
      margin-left: $gutter-mobile;

      @media (min-width: $screen-sm) {
        margin-left: $gutter-tablet;
      }

      @media (min-width: $screen-xl) {
        margin-left: $gutter-desktop;
      }
    }

    &__popover {
      &--arrow {
        border-color: map-get($generic-color-variants, 'success');
      }

      &--inner {
        box-shadow: 1px 2px 15px 0 rgba(0, 0, 0, 0.16);
        background-color: white;
        padding: 0 0 4px 0;
        max-width: 250px;
        line-height: 1.6;
        font-size: 0.79rem;

        &__sync {
          margin-bottom: 5px;
          background-color: map-get($generic-color-variants, 'success');
          padding: 4px 0;
          text-align: center;
          color: white;
          font-weight: 600;

          &__date {
            padding: 0 5px;
            font-weight: 400;
          }
        }

        &__location {
          padding: 0 $gutter-mobile;
          &__not-publish {
            color: map-get($generic-color-variants, 'blue-cerulean');
          }
        }

        &__time {
          padding: 0 $gutter-mobile;
          color: map-get($generic-color-variants, 'warning');
        }

        &__mobileValidation {
          text-align: center;
          &--validate {
            color: map-get($generic-color-variants, 'success');
            font-size: 1.9rem;
          }

          &--close {
            margin-left: 9px;
            color: map-get($generic-color-variants, 'error');
            font-size: 1.9rem;
          }
        }
      }
    }

    &__publish {
      position: fixed;
      right: $gutter-mobile;
      bottom: calc(#{$navigation-height} + #{$gutter-mobile});
      z-index: 2;

      @media (min-width: $screen-sm) {
        position: relative;
        right: initial;
        bottom: initial;
        margin-left: $gutter-tablet;
      }

      @media (min-width: $screen-xl) {
        margin-left: $gutter-desktop;
      }
    }

    &__popoverFilter {
      padding: $gutter-mobile;
      min-width: 280px;

      @media (min-width: $screen-sm) {
        padding: $gutter-tablet;
        min-width: 340px;
      }
    }
  }

  &__list {
    position: relative;
    margin-bottom: -16px;

    @media (min-width: $screen-sm) {
      margin: 0 (-$gutter-tablet) (-$gutter-tablet);
    }

    @media (min-width: $screen-md) {
      display: none;
    }
    &__select {
      margin-left: 7px;
      width: auto;
      height: auto;
      color: $grey-french;
    }
    &__logo {
      margin-right: $gutter-mobile;
      width: 36px;
      height: 36px;

      @media (min-width: $screen-sm) {
        margin-right: $gutter-tablet;
      }
    }
    &__content {
      flex: 1;
      &__name {
        font-weight: 600;
      }
      &__address {
        @include text-ellipsis(1, 12px);

        margin-top: 3px;
        font-size: $font-size-sm;
        font-style: normal;

        @media (min-width: $screen-sm) {
          @include text-ellipsis(1, 14px);

          margin-right: $gutter-tablet;
          font-size: $font-size-default;
        }
      }
    }
    &__status {
      margin-left: $gutter-mobile;
      color: map-get($generic-color-variants, 'error');
      &--1,
      &--10,
      &--20 {
        color: map-get($generic-color-variants, 'success');
      }
    }
  }
  &__table {
    display: none;

    @media (min-width: $screen-md) {
      display: block;
      height: 100%;
      overflow-y: auto;
    }
    &__select {
      width: auto;
      height: auto;
      color: $grey-french;
    }
    &__status {
      color: map-get($generic-color-variants, 'error');
      &--1,
      &--10,
      &--20 {
        color: map-get($generic-color-variants, 'success');
      }
    }
    &__block {
      display: flex;
      align-items: center;
      &__logo {
        width: 36px;
        height: 36px;

        @media (min-width: $screen-md) {
          margin-right: $gutter-tablet;
        }
      }
      &__content {
        display: flex;
        flex-direction: column;
        &__name {
          font-weight: 600;
        }
        &__address {
          @include text-ellipsis(1, 14px);

          margin-top: 3px;
          font-size: $font-size-default;
          font-style: normal;
        }
      }
      &__icon {
        border: 1px solid;
        border-radius: 50%;
        padding: 3px;
        color: map-get($generic-color-variants, 'data');
        &:first-child {
          margin-right: 8px;
        }
        &--inactive {
          color: $generic-color-bg-disabled;
        }
      }
    }

    &__link {
      @include full-link;
    }
  }
}
</style>
